import React, { Component } from "react";
import CenteredSpinner from "../components/CenteredSpinner";
import JSPivotTable from "../components/PivotTable";
import { Card } from "react-bootstrap";

import axios from 'axios';
import pako from 'pako';
import Papa from 'papaparse'

import "./PivotTable.css";


export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: [],
      filename: (this.props.location.state.s3_location
                                .split('/').pop().split('?')[0])
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      let pivot = this
      axios.get(this.props.location.state.s3_location, 
              { responseType: 'arraybuffer', timeout: 50000 })
                                        .then(function (response){
          // decompress gz
          const data = pako.inflate(response.data, { to: 'string' } )

          var rows = []
          Papa.parse(data, {
              skipEmptyLines: true,
              error: function(e){ alert(e) },
              chunk: function(result){ 
                rows = rows.concat(result.data);
              },
              complete: function(parsed){
                pivot.setState({ 
                    data: rows, 
                    isLoading: false 
                })
              }
          })

        })

    } catch (e) {
      alert(e);
    }

  }

  
  render() {
    return (
      <div className="pivot-table">
        <Card className="mb-2">
          <Card.Body>
            <Card.Title>Pivot Table <br /><small>{ this.state.filename }</small></Card.Title>
            { !this.state.isLoading ?
            <JSPivotTable initData={this.state.data} />
            : 
            <CenteredSpinner />        
            }
          </Card.Body>
        </Card>
      </div>
    );
  }
}

 