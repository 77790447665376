import React, { Component } from "react";
import { API, Cache } from "aws-amplify";

import BatchForm from "../components/BatchForm";
import CenteredSpinner from "../components/CenteredSpinner";
import { Button, Card, Table, Nav, Form } from "react-bootstrap";

import { faTrash, faHourglassStart, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Jobs.css";

export default class Batch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      message: false,
      selectedBatchOwner: this.props.session.idToken.payload.email,
      lastKey: false,
      batch: []
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getData();

    } catch (e) {
      alert(e);
    }
  }


  async getData() {
    this.setState({ isLoading: true });

    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }

    let url = this.getBatchUrl()
    let response = Cache.getItem(url)
    
    if (!response) {
      try {
        response = await API.get("backend", url, options);
      }
      catch (err) {
        if (err.response && err.response.status == 401) {
          window.location.reload();
          return
        }
        else {
          console.log(JSON.stringify(err))
        }
      }
      Cache.setItem(url, response)
    }

    console.log(response)

    this.setState({
      isLoading: false,
      batch: this.state.batch.concat(response.items),
      lastKey: response.last_key
    });

  }


  invalidateCache = () => {
    Cache.getAllKeys().map((key) => {
      if (key.startsWith('/batch?')) {
        console.log('invalidate ' + key)
        Cache.removeItem(key)
      }
      return true
    })
  }


  getBatchUrl = () => {
    let url = "/batch?"
    if (this.state.lastKey)
      url += "&last_key=" + this.state.lastKey

    if (this.state.selectedBatchOwner === 'delete') {
      // get list of deleted batch
      url += "&owner=deleted_batch"
    }

    return url
  };

  // LOAD MORE
  handleLoadMore = data => {
    this.setState({ isLoading: true }, function () {
      this.getData()
    })
  };

  handleDeleteBatch = (event, batch_id) => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete batch "+ batch_id +" and revoke all the tasks?"
    );
    if (!confirmed) {
      return;
    }

    this.props.setMessage('Please wait')
    
    API.del("backend", "/batch", {
      body: { id: batch_id },
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
      this.handleSync();
      this.props.setMessage('Batch deleted', true)
    }).catch(error => {
      this.props.setMessage(error.response)
    });
  }

  handleSync = () => {
    this.setState({ isLoading: false, lastKey: false, batch: [] }, function () {
      this.invalidateCache()
      this.getData()
    });

  }

  renderSawCommand(batch) {
    if (batch.ecs_config){
    
    //   const ecs_config = JSON.parse(batch.ecs_config || "{}")
      return Object.keys(batch.ecs_config).map(cluster_name =>
        <>
          <small><small>saw get --start -4h /limbic-workers --prefix /ecs/{process.env.REACT_APP_STAGE}/{batch.id}/{cluster_name}</small></small><br />
        </>
      ) 
    }
    const workers = JSON.parse(batch.workers || "{}")
    return Object.keys(workers).map(cluster_name =>
      <>
        <small><small>saw get --start -4h /limbic-workers --prefix /limbic-{process.env.REACT_APP_STAGE}-{batch.id}-{cluster_name}</small></small><br />
      </>
    )

  }

  renderBatchList(batch) {
    return batch.map(
      (batch, i) =>
        <tr key={batch.id}>
          <td>
              <Nav.Item>
                <Nav.Link style={{ padding: "0" }} href={"/batch/batch_" + batch.id}>{batch.name}</Nav.Link>
              </Nav.Item>
            {this.renderSawCommand(batch)}
          </td>
          <td>{batch.created_at.slice(0, 16)}</td>
          <td>{batch.done_tasks === batch.total_tasks ? 'yes' : 'no'} {batch.last_node_success === false ? ' (failed)' : ''}</td>
          <td>{batch.owner ? batch.owner : "limbic"}</td>
          <td>{batch.total_tasks ? batch.done_tasks + "/" + batch.total_tasks : "starting"}</td>
          <td> 
            <a href={"/batch/batch_" + batch.id} onClick={((e) => this.handleDeleteBatch(e, batch.id))} className="tooltip-test" title="Delete" >
              <FontAwesomeIcon icon={faTrash} />
            </a>
          </td>
        </tr>
    );
  }

  render() {
    return (
      <div className="batch">

        <Card>
          <Card.Body>
            <Card.Title>Batch</Card.Title>

            <Table bordered responsive size="lg">
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Created</th>
                  <th>Done</th>
                  <th>Owner</th>
                  <th>Progress</th>
                  <th></th>
                </tr>
              </thead>
              {!this.state.isLoading ?
                <tbody>
                  {this.renderBatchList(this.state.batch)}
                </tbody>
                : <></>
              }
            </Table>


            {this.state.isLoading
              ? <CenteredSpinner />
              : <>
                <Button variant="outline-secondary" disabled={!this.state.lastKey} onClick={this.handleLoadMore}>Load more</Button>
                <Button variant="outline-secondary" onClick={this.handleSync} className="float-right">
                  <FontAwesomeIcon icon={faSyncAlt} /> Sync
                </Button>
              </>
            }

          </Card.Body>
        </Card>

        <Card className="mt-2">
          <Card.Body>
            <Card.Title>Start new batch</Card.Title>
            <BatchForm setMessage={this.props.setMessage} session={this.props.session} />
          </Card.Body>
        </Card>


      </div>
    );
  }
}

