import React, { Component } from "react";
import { API } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import CenteredSpinner from "../components/CenteredSpinner";
import { withRouter } from "react-router";

import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/github';


const axios = require('axios');
axios.defaults.timeout = 10000; // 10 seconds etc

class NetworkForm extends Component {
  

  constructor(props) {
   super(props)

   this.state = {
      isLoading: true,
      isSubmitting: false,
      commitId: false,
      name: "",
      description: "",
      network: "",
      created: "",
      last_update: "",
      author: "",
      history: []
    }
  }

  async componentDidMount() {
   
    if (!this.props.path) {
      // new form
      this.setState({ isLoading: false });
      return;
    }

    try {
      this.getFileDetails(false);
    } catch (e) {
      alert(e);
    }
  }

  async getFileDetails(commitId) {
    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    let url = "/networks?path="+this.props.path
    // if no commitId given load most recent 
    if (commitId)
      url += "&commit_id="+commitId

    this.setState({ isLoading: true });

    const response = await API.get("backend", url, options);
    this.setState({
      isLoading: false,
      commitId: response['commit_id'],
      name: response['path'],
      description: response['message'],
      author: response['author'],
      network: response['content'],
      created: response['created'],
      history: response['history'],
      last_update: response['last_update']
    });

  }


  validateForm() {

    // THIS IS PROBLEMATIC... 
    // we dont rerender on changes in json editor... save button stays disabled even when valid JSON network 
    // try {
    //     JSON.parse(this.state.network);
    // } catch(e) {
    //     console.log(e)
    //     return false
    // }
  

    if ( /^([a-z0-9\s_@\-^!#$%&+={}[\]]+)+([a-z0-9\s_@\-^!#$%&+={}[\]/]*)+\.json$/i.test(this.state.name) === false)
      // network name must be a valid linux path
      return false

    return true

  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.props.setMessage('Please wait')
    this.setState({ isSubmitting: true });
    
    API.post("backend", "/networks", {
      body: {
        username: this.props.session.idToken.payload.email,
        path: this.state.name,
        description: this.state.description,
        network: this.state.network
     },
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
        this.setState({ isSubmitting: false });
        this.props.setMessage('Network saved', true)        
    }).catch(error => {
        this.props.setMessage(error.response)
    });
    
  }

  // JSON editor related
  handleNetworkUpdate = val => {
    this.setState({ network: val })
  }

  handleLoadVersion = event => {
    if (this.state.commitId !== event.target.value)
      this.getFileDetails(event.target.value)
  }

  handleStartJob = event => {
    window.confirm(
      "todo..."
    );
    return 
  }

  render() {
    return (
      <div>
      { !this.state.isLoading ?
      
      <Form onSubmit={this.handleSubmit}>

        <Form.Group controlId="network">
          <Form.Label>JSON</Form.Label>
           <AceEditor
              mode="json"
              value={this.state.network}
              onChange={ (raw)=>{this.handleNetworkUpdate(raw)} }
              theme="github"
              width="100%"
              maxLines={40}
              minLines={12}
              name="network"
            />
        </Form.Group>

        <Form.Group controlId="description">        
          <Form.Label>Commit message</Form.Label>
          <Form.Control
            name="description"
            onChange={this.handleChange}
            value={this.state.description}
            type="input" 
          />
        </Form.Group>

        { this.props.path ?
        <>
        <Form.Group controlId="commit">        
          <Form.Label>Commit</Form.Label>
          <Form.Control
            name="commit"
            disabled={true}
            value={this.state.commitId}
            type="input" 
          />
        </Form.Group>
          <Form.Group controlId="created">        
            <Form.Label>Created</Form.Label>
            <Form.Control
              disabled={ true } 
              name="created"
              value={this.state.created}
              type="input" 
            />
          </Form.Group>
          <Form.Group controlId="updated">        
            <Form.Label>Last update</Form.Label>
            <Form.Control
              disabled={ true } 
              name="updated"
              value={this.state.last_update}
              type="input" 
            />
          </Form.Group>
          <Form.Group controlId="author">        
            <Form.Label>Author</Form.Label>
            <Form.Control
              disabled={ true } 
              name="author"
              value={this.state.author}
              type="input" 
            />
          </Form.Group>

          <Form.Group controlId="history">        
            <Form.Label>History</Form.Label>
            <Form.Control 
                as="select"
                className="d-flex justify-content-end owner-dropdown"
                value={this.state.commitId}
                onChange={ this.handleLoadVersion }
              >
                {
                  this.state.history.map(function (commit) {
                    const commit_name = "[" + commit.created + "] [" + commit.id + "] " + commit.message.slice(0, 24) + " - " + commit.author
                    return <option key={commit.id} value={commit.id}>{commit_name}</option>
                  }, this)
              }
            </Form.Control>
          </Form.Group>
        </>
        : <></>

      }


        { this.props.path ?  
          <>
          <LoaderButton
            block
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isSubmitting}
            text="update"
            loadingText="updating"
          />
          <LoaderButton
            block
            disabled={!this.validateForm()}
            onClick={this.handleStartJob}
            text="start job"
            loadingText="loading"
          />
          </>
            :
          <LoaderButton
            block
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isSubmitting}
            text="save"
            loadingText="saving"
          />
          }

      </Form>
      
       : <CenteredSpinner />
        
      }
      </div>
    );
  }
}

export default withRouter(NetworkForm)