import React, { Component } from "react";
import { API } from "aws-amplify";
import { Form, Dropdown, DropdownButton } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";


const ENVIROMENT_DOCKER_RUNTIME = 0
const ENVIROMENT_SYSBOX_RUNTIME = 1

export default class WorkersForm extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isLoading: null,
      service: null,
      name: "default",
      ecr_image_name: "cortex_v2",
      ec2_instance_type: "t3.medium",
      ec2_instance_vol: 100,
      ec2_instance_count: "1",
      limbic_max_cache: 75,
      celery_conc: "1",
      runtime: ENVIROMENT_DOCKER_RUNTIME
    };
  }

  validateForm() {
    return (this.state.name.length > 0 && this.state.ec2_instance_type.length > 0);
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    this.props.setMessage("Please wait");

    let body = {
      username: this.props.session.idToken.payload.email,
      name: this.state.name,
      ecr_image_name: this.state.ecr_image_name,
      ec2_instance_type: this.state.ec2_instance_type,
      ec2_instance_count: this.state.ec2_instance_count,
      ec2_instance_vol: this.state.ec2_instance_vol,
      limbic_max_cache: this.state.limbic_max_cache * 1024 * 1024 * 1024, // GB => B
      celery_conc: this.state.celery_conc
    }

    if (this.state.runtime == ENVIROMENT_SYSBOX_RUNTIME){
      body["ubuntu_sysbox"] = true
    }

    API.post("backend", "/workers", {
      body,
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
        this.setState({ isLoading: false });
        if (response.status === 400){
          this.props.setMessage(response.message, true)
        }
        else{
          this.props.setMessage('Initilizing cluster', true)
          this.props.handleSync()
        }
    }).catch(error => {
        this.props.setMessage(error.response)
    });
      
  }


  render() {
    const runtimeTitle = (this.state.runtime == ENVIROMENT_DOCKER_RUNTIME) ? 'Docker Runtime' : 'Sysbox Runtime'
    return (
      <Form onSubmit={this.handleSubmit}>

        <Form.Group controlId="queue_name">        
          <Form.Label><small>Celery queue name (e.i. default, cpu, gpu, etc)</small></Form.Label>
          <Form.Control
            name="name"
            onChange={this.handleChange}
            value={this.state.name}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="ecr_image_name">
          <Form.Label><small>Worker image</small></Form.Label>
          <Form.Control
            name="ecr_image_name"
            onChange={this.handleChange}
            value={this.state.ecr_image_name}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="ec2_instance_type">
          <Form.Label><small>EC2 instance type (e.i. t3.medium, m4.large, etc)</small></Form.Label>
          <Form.Control
            name="ec2_instance_type"
            onChange={this.handleChange}
            value={this.state.ec2_instance_type}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="ec2_instance_count">
          <Form.Label><small>Number of instances</small></Form.Label>
          <Form.Control
            name="ec2_instance_count"
            onChange={this.handleChange}
            value={this.state.ec2_instance_count}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="ec2_instance_vol">
          <Form.Label><small>Instances volume (GB)</small></Form.Label>
          <Form.Control
            name="ec2_instance_vol"
            onChange={this.handleChange}
            value={this.state.ec2_instance_vol}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="limbic_max_cache">
          <Form.Label><small>Limbic cache size (GB)</small></Form.Label>
          <Form.Control
            name="limbic_max_cache"
            onChange={this.handleChange}
            value={this.state.limbic_max_cache}
            type="input" 
          />
        </Form.Group>


        <Form.Group controlId="celery_conc">
          <Form.Label><small>Worker celery concurrency</small></Form.Label>
          <Form.Control
            name="celery_conc"
            onChange={this.handleChange}
            value={this.state.celery_conc}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="celery_conc">
          <Form.Label><small>Worker enviroment</small></Form.Label>
          <DropdownButton id="dropdown-basic-button" title={ runtimeTitle }>
            <Dropdown.Item eventKey="action-1" onSelect={() => this.setState({ runtime: ENVIROMENT_DOCKER_RUNTIME })}>Docker runtime</Dropdown.Item>
            <Dropdown.Item eventKey="action-2" onSelect={() => this.setState({ runtime: ENVIROMENT_SYSBOX_RUNTIME })}>Sysbox runtime</Dropdown.Item>
          </DropdownButton>
        </Form.Group>

        <LoaderButton
          block
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="start new cluster"
          loadingText="Starting"
        />
      </Form>
      
    );
  }
}
