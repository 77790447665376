import React, { Component } from "react";
import { API, Cache } from "aws-amplify";

import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { withRouter } from "react-router";

import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/github';

import config from "../config";

class JobsForm extends Component {
  constructor(props) {
    super(props)

    var is_public = false
    if (this.props.job) {
      if ('network' in this.props.job.network) {
        // ASG style
        is_public = (this.props.job.network.network[0].user.instance_type === 'public_jobs')
      }
      else {
        is_public = (this.props.job.network[0].user.instance_type === 'public_jobs')
      }

    }


    this.state = {
      isLoading: null,
      jobDetailsLoaded: false,
      is_public: is_public,
      name: "",
      desc: "",
      sessions: "",
      network: ""
    }
  }

  async componentDidMount() {

    if (this.props.job) {
      let desc = ''
      if (this.props.job['desc'])
        desc = this.props.job['desc']
      this.setState({
        jobDetailsLoaded: true,
        name: this.props.job['name'],
        desc: desc,
        network: JSON.stringify(this.props.job['network'], null, 2),
        sessions: JSON.stringify(this.props.job['sessions'], null, 2)
      });

      return;
    }
    if (!this.props.job) {
      return;
    }
  }

  validateForm() {
    return (this.state.name.length > 0 && this.state.sessions.length > 0);
  }

  formBody() {
    let network = false
    let sessions = false
    try {
      if (this.state.sessions.startsWith('s3://')) {
        sessions = this.state.sessions
      }
      else {
        sessions = JSON.parse(this.state.sessions);
      }
    } catch (e) {
      this.props.setMessage('Sessions JSON is invalid')
      this.setState({ isLoading: false });
      return false
    }
    try {
      network = JSON.parse(this.state.network);
    } catch (e) {
      this.props.setMessage('Network JSON is invalid')
      this.setState({ isLoading: false });
      return false
    }

    return {
      owner: this.props.session.idToken.payload.email,
      name: this.state.name,
      desc: this.state.desc,
      sessions: sessions,
      network: network
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleUpdate = event => {
    event.preventDefault();

    let body = this.formBody()
    if (!body)
      return

    body['id'] = this.props.job.id

    this.props.setMessage("Please wait");
    API.put("backend", "/jobs", {
      body: body,
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
      this.setState({ isLoading: false });
      // invalidate jobs list cache 
      Cache.getAllKeys().map((key) => {
        if (key.startsWith('/jobs?')) {
          console.log('invalidate ' + key)
          Cache.removeItem(key)
        }
        return true
      })
      this.props.setMessage('Job updated')
      this.props.history.push('/jobs')
    }).catch(error => {
      this.props.setMessage(error.response)
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    let body = this.formBody()
    if (!body)
      return

    this.props.setMessage("Please wait");
    // interacts with celery - connect to lambda with VPC
    API.post("backend", "/jobs", {
      body: body,
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
      this.setState({ isLoading: false });

      if (response.status == 500) {
        this.props.setMessage(response.message)
        return
      }

      Cache.getAllKeys().map((key) => {
        // invalidate jobs list cache 
        if (key.startsWith('/jobs?')) {
          console.log('invalidate ' + key)
          Cache.removeItem(key)
        }
        return true
      })

      this.props.setMessage('Job started', true)
      if (this.props.history.location.pathname === '/jobs')
        window.location.reload();
      else
        this.props.history.push('/jobs')

    }).catch(error => {
      this.props.setMessage(error.response)
    });

  }

  // JSON editor related
  handleSessionUpdate = val => {
    this.setState({ sessions: val })
  }

  handleNetworkUpdate = val => {
    this.setState({ network: val })
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.state.jobDetailsLoaded !== nextState.jobDetailsLoaded)
      return true
    // dont rerender on sessions or network update
    if (this.state.sessions !== nextState.sessions)
      return false
    if (this.state.network !== nextState.network)
      return false
    return true
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>


        <Form.Group controlId="sessions">

          {this.props.job ?
            <><Form.Group controlId="api">
              <Form.Label>API details endpoint</Form.Label>
              <Form.Control
                readOnly={true}
                name="api"
                value={config.apiGateway.URL + "/jobs?job_id=" + this.props.job.id}
                type="input"
              />
            </Form.Group>
              <Form.Group controlId="api_results">
                <Form.Label>API results endpoint</Form.Label>
                <Form.Control
                  readOnly={true}
                  name="api_results"
                  value={config.apiGateway.URL + "/jobtasks?job_id=" + this.props.job.id}
                  type="input"
                />
              </Form.Group></>
            : <></>
          }

          <Form.Label>Sessions</Form.Label>
          <AceEditor
            mode="json"
            value={this.state.sessions}
            theme="github"
            onChange={(raw) => { this.handleSessionUpdate(raw) }}
            width="100%"
            maxLines={20}
            name="sessions"
          />
        </Form.Group>

        <Form.Group controlId="network">
          <Form.Label>Network</Form.Label>
          <AceEditor
            mode="json"
            value={this.state.network}
            onChange={(raw) => { this.handleNetworkUpdate(raw) }}
            theme="github"
            width="100%"
            maxLines={20}
            name="network"
          />
        </Form.Group>

        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            onChange={this.handleChange}
            value={this.state.name}
            type="input"
          />
        </Form.Group>

        <Form.Group controlId="desc">
          <Form.Label>Description</Form.Label>
          <Form.Control
            name="desc"
            onChange={this.handleChange}
            value={this.state.desc}
            type="input"
          />
        </Form.Group>

        <LoaderButton
          block
          disabled={!this.validateForm() || (this.state.is_public)}
          type="submit"
          isLoading={this.state.isLoading}
          text="start new job"
          loadingText="starting"
        />

        {this.props.job ?
          <LoaderButton
            block
            disabled={!this.validateForm()}
            onClick={this.handleUpdate}
            isLoading={this.state.isLoading}
            text="update"
            loadingText="updating"
          />
          : <></>
        }
      </Form>

    );
  }
}

export default withRouter(JobsForm)