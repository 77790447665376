import React, { Component } from "react";
import { API } from "aws-amplify";


import CenteredSpinner from "../components/CenteredSpinner";
import WorkersForm from "../components/WorkersForm";

import { Card, Table, Button, Modal } from "react-bootstrap";

import { faSyncAlt  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import "./Workers.css";

export default class Workers extends Component {
 constructor(props) {
    super(props);

    this.state = {
      lastKey: false,
      isLoading: true,
      isLoadingNewPage: true,
      logsModalShow: false,
      dataText: "",
      message: false,
      clusters: []
    };

    this.handleSync = this.handleSync.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getClusters();
      
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async getClusters() {
    this.setState({ isLoadingNewPage: true });
     
    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    let url = "/workers"
    if (this.state.lastKey)
      url += "?last_key="+this.state.lastKey
   
    const response = await API.get("backend", url, options);
    
    this.setState({
      isLoading: false,
      isLoadingNewPage: false,
      clusters: response.asgs,
    });
  }

__apiRequestOptions = (asgName, action) => {
    return {
      headers: {
        'Authorization': this.props.session.idToken.jwtToken
      },
      body: {
        action: action,
        asg_name: asgName,
        username: this.props.session.idToken.payload.email
      }
    }
  }

  handleLogs = (e, asgName) => {
    this.props.setMessage("Please wait");

    API.post("backend", "/workers", 
        this.__apiRequestOptions(asgName, 'logs'))
    .then(response => {
        this.setState({
          dataText: response.logs,
          logsModalShow: true
        })        
    }).catch(error => {
        this.props.setMessage(error)
    });
  }

  handleDetails = (e, asgName) => {
    this.props.setMessage("Please wait");

    API.post("backend", "/workers", 
        this.__apiRequestOptions(asgName, 'details'))
    .then(response => {

        let dataText = "INSTANCE TYPE<br /><br />" + response.instance_type 
        dataText += "<br /><br />CLOUD INIT SCRIPT"
        dataText += response.user_data.replace(/\n/g, '<br />')
        this.setState({
          dataText: dataText,
          logsModalShow: true
        })        
    }).catch(error => {
        this.props.setMessage(error)
    });
  }

  handleSync = () => {
    this.setState({ isLoadingNewPage: false, notebooks: [], lastKey: false }, function(){
      this.getClusters()      
    });
  }

  
  handleDeleteCluster = (e, name) => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this cluster?"
    );

    if (!confirmed) {
      return;
    }

    this.props.setMessage("Please wait");

    API.post("backend", "/workers", 
        this.__apiRequestOptions(name, 'delete'))
    .then(response => {
        this.handleSync()
    }).catch(error => {
        this.props.setMessage(error)
    });
  }

  renderClustersList(clusters) {
    return clusters.map(
      (cluster, i) =>
          <tr key={cluster.name}>
            <td>
              {cluster.name}<br />
              <small>created on: {cluster.created} </small><br />
              <small>status: {cluster.status}</small><br />
              <small>saw get --start -4h /limbic-workers --prefix /{cluster.name}</small>
            </td>
            <td>{cluster.capacity} / {cluster.max}</td>
            <td>
                <Button variant="primary" size="sm" 
                  onClick={((e) => this.handleDetails(e, cluster.name))}>
                    details
                </Button>
            </td>

            <td>
                <Button variant="primary" size="sm" 
                  onClick={((e) => this.handleDeleteCluster(e, cluster.name))}>
                    delete
                </Button>
            </td>

          </tr>
    );
  }

  render() {
    return (
      <div className="notebooks">
        { !this.state.isLoading ?
        <>
        
        <Modal
          size="lg"
          show={this.state.logsModalShow}
          onHide={() => this.setState({ logsModalShow: false })}
          scrollable="1"
          aria-labelledby="example-modal-sizes-title-lg">
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Data
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <small dangerouslySetInnerHTML={{__html: this.state.dataText}} />
          </Modal.Body>
        </Modal>

        <Card>
          <Card.Body>
            <Card.Title>Clusters</Card.Title>
            
            <Table bordered responsive size="lg">
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Capacity</th>
                  <th colSpan="2">Actions</th>
                </tr>
              </thead>
              <tbody>
                { this.renderClustersList(this.state.clusters) }
              </tbody>
            </Table>

          </Card.Body>
        </Card>

        <Card className="mt-2">
          <Card.Body>
            <Card.Title>Start new cluster</Card.Title>
            <WorkersForm
              setMessage={this.props.setMessage} 
              handleSync={this.handleSync} 
              session={this.props.session} />            
          </Card.Body>
        </Card>
        </>

        : <CenteredSpinner />
        
        }
      </div>
    );
  }
}

 