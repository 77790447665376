import React, { Component } from "react";
import { API, Cache } from "aws-amplify";

import JobsForm from "../components/JobsForm";
import CenteredSpinner from "../components/CenteredSpinner";
import { Button, Card, Table, Nav, Form } from "react-bootstrap";

import { faTrash, faHourglassStart, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Jobs.css";

export default class Jobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      message: false,
      selectedJobsOwner: this.props.session.idToken.payload.email,
      jobOwners: [],
      lastKey: false,
      jobs: []
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getJobs();

    } catch (e) {
      alert(e);
    }
  }


  async getJobs() {
    this.setState({ isLoading: true });

    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }

    let url = this.getJobsUrl()

    let response = Cache.getItem(url)
    if (!response) {
      try {
        response = await API.get("backend", url, options);
      }
      catch (err) {
        if (err.response && err.response.status == 401) {
          window.location.reload();
          return
        }
        else {
          console.log(JSON.stringify(err))
        }
      }
      Cache.setItem(url, response)
    }
    console.log(response)
    this.setState({
      isLoading: false,
      jobs: this.state.jobs.concat(response.items),
      state_machine: response.state_machine,
      lastKey: response.last_key,
      jobOwners: Object.keys(response.users)
    });

  }


  invalidateCache = () => {
    Cache.getAllKeys().map((key) => {
      if (key.startsWith('/jobs?')) {
        console.log('invalidate ' + key)
        Cache.removeItem(key)
      }
      return true
    })
  }


  getJobsUrl = () => {
    let url = "/jobs?"
    if (this.state.lastKey)
      url += "&last_key=" + this.state.lastKey

    if (this.state.selectedJobsOwner === 'all') {
      // pass, get all jobs
    }
    else if (this.state.selectedJobsOwner === 'delete') {
      // get list of deleted jobs
      url += "&owner=deleted_jobs"
    }
    else {
      // get specyfic owner jobs
      url += "&owner=" + encodeURIComponent(this.state.selectedJobsOwner)
    }
    return url
  };

  // LOAD MORE
  handleLoadMore = data => {
    this.setState({ isLoading: true }, function () {
      this.getJobs()
    })
  };

  handleOwnerChange = event => {
    this.setState({
      selectedJobsOwner: event.target.value,
      lastKey: false,
      jobs: []
    }, function () {
      this.getJobs()
    })
  }

  handleDeleteJob = (event, job_id) => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this job and revoke all the tasks?"
    );
    if (!confirmed) {
      return;
    }

    this.props.setMessage('Please wait')
    // interacts with celery - connect to lambda with VPC
    API.del("backend", "/jobs", {
      body: { id: job_id },
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
      this.handleSync();
      this.props.setMessage('Job deleted', true)
    }).catch(error => {
      this.props.setMessage(error.response)
    });
  }

  handleSync = () => {
    this.setState({ isLoading: false, lastKey: false, jobs: [] }, function () {
      this.invalidateCache()
      this.getJobs()
    });

  }

  renderSawCommand(job, state_machine) {
    
    if (job.ecs_config && job.ecs_config != "{}"){
      const ecs_config = JSON.parse(job.ecs_config || "{}")
      return Object.keys(ecs_config).map(cluster_name =>
        <>
          <small><small>saw get --start -4h /limbic-workers --prefix /ecs/{process.env.REACT_APP_STAGE}/{job.id}/{cluster_name}</small></small><br />
        </>
      ) 
    }
    else{
      let link = "https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/v2/executions/details/arn:aws:states:us-east-1:328907861098:execution:" + state_machine + ":"+ process.env.REACT_APP_STAGE + "-" + job.id
      return (
        <>
          <br /><small><small><a href={link} target="_blank">state machine</a></small><br /></small>
        </>
      )
    }
    const workers = JSON.parse(job.workers || "{}")
    return Object.keys(workers).map(cluster_name =>
      <>
        <small><small>saw get --start -4h /limbic-workers --prefix /limbic-{process.env.REACT_APP_STAGE}-{job.id}-{cluster_name}</small></small><br />
      </>
    )

  }

  renderJobsList(jobs, state_machine) {
    return jobs.map(
      (job, i) =>
        <tr key={job.id}>
          <td>
            {job.total_tasks || 1==1 ?
              (<Nav.Item>
                <Nav.Link style={{ padding: "0" }} href={"/jobs/job_" + job.id}>{job.name}</Nav.Link>
              </Nav.Item>)
              :
              <div>{job.name}</div>
            }
            {this.renderSawCommand(job, state_machine)}
          </td>
          <td>{job.created_at.slice(0, 16)}</td>
          <td>{job.done_tasks === job.total_tasks ? 'yes' : 'no'} {job.last_node_success === false ? ' (failed)' : ''}</td>
          <td>{job.owner ? job.owner : "limbic"}</td>
          <td>{job.status ? job.status : "done" }</td>
          <td> {job.total_tasks || 1==1?
            <a href={"/jobs/job_" + job.id} onClick={((e) => this.handleDeleteJob(e, job.id))} className="tooltip-test" title="Delete" >
              <FontAwesomeIcon icon={faTrash} />
            </a>
            : <FontAwesomeIcon icon={faHourglassStart} />
          }
          </td>
        </tr>
    );
  }

  render() {
    return (
      <div className="jobs">

        <Card>
          <Card.Body>
            <Card.Title>Jobs</Card.Title>


            <Form.Control
              as="select"
              className="d-flex justify-content-end owner-dropdown"
              value={this.state.selectedJobsOwner}
              onChange={this.handleOwnerChange}
            >
              {
                this.state.jobOwners.map(function (owner) {
                  return <option key={owner} value={owner}>{owner}</option>
                }, this)
              }
              <option key="-----" value="-----" disabled>-----</option>
              <option key="all" value="all">all users</option>
              <option key="------" value="------" disabled>-----</option>
              <option key="deleted" value="deleted">deleted</option>
            </Form.Control>

            <Table bordered responsive size="lg">
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Created</th>
                  <th>Done</th>
                  <th>Owner</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {!this.state.isLoading ?
                <tbody>
                  {this.renderJobsList(this.state.jobs, this.state.state_machine)}
                </tbody>
                : <></>
              }
            </Table>


            {this.state.isLoading
              ? <CenteredSpinner />
              : <>
                <Button variant="outline-secondary" disabled={!this.state.lastKey} onClick={this.handleLoadMore}>Load more</Button>
                <Button variant="outline-secondary" onClick={this.handleSync} className="float-right">
                  <FontAwesomeIcon icon={faSyncAlt} /> Sync
                </Button>
              </>
            }

          </Card.Body>
        </Card>

        <Card className="mt-2">
          <Card.Body>
            <Card.Title>Start new job</Card.Title>
            <JobsForm setMessage={this.props.setMessage} session={this.props.session} />
          </Card.Body>
        </Card>


      </div>
    );
  }
}

