import React from "react";
import { Spinner } from "react-bootstrap";
import "./CenteredSpinner.css";

export default ({
  ...props
}) =>
  <div className="row">
      <div className="col-lg-1 spinner-centered">
        <Spinner animation="grow" role="status" variant="secondary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
  </div>;
