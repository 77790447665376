import React, { Component, useState } from "react";
import { API } from "aws-amplify";
import BatchForm from "../components/BatchForm";
import CenteredSpinner from "../components/CenteredSpinner";
import { Alert, Button, Modal, Card, Table, Nav, Form, Toast } from "react-bootstrap";

import "./JobsTasks.css";

function ReRunModal(props) {
  const [show, setShow] = useState(false);
  
  let jobtasks = Object.keys(props.checked).map(jt => jt)
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirm = () => {
    setShow(false)
    
    API.post("backend", "/batch_restart", {
      body: { batchtasks: jobtasks },
      headers: {
        Authorization: props.session.idToken.jwtToken
      },
      timeout: 30 * 1000 
    }).then(response => {
    
      if (response.status == 500) {
        alert(response.message)
        return
      }

      alert('Done')

    }).catch(error => {
      alert(error.response)
    });
  };

  return (
    <>

      <Button variant="outline-secondary" className="load-all" onClick={handleShow}>
        Re-run selected
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirm to re-run {jobtasks.length}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleConfirm}>confirm</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default class BatchTasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      job_id: this.props.location.pathname.split('_')[1],
      showReport: false,
      isLoading: true,
      message: false,
      lastKey: false,
      job: false,
      filterNodeType: 'all',
      ecsTasksCount: 0,
      filterDone: 'all',
      nodeTypes: ['all'],
      doneTypes: ['done', 'not done', 'all'],
      jobtasks: [],
      checked: {}
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getBatchTasks(false);
    } catch (e) {
      alert(e);
    }
  }

  handleLoadMore = data => {
    this.setState({ isLoading: true }, function () {
      this.getBatchTasks(false)
    })
  };
  
  handleLoadAll = data => {
    this.setState({ jobtasks: [], isLoading: true }, function () {
      this.getBatchTasks(true)
    })
  };

  filterByNodeName = (node_id, i) => {
    if (this.state.filterNodeType == 'all'){
      return true
    }
    return this.state.jobtasks['node_' + node_id].name.includes(this.state.filterNodeType)
  }

  filterByDone = (node_id, i) => {
    if (this.state.filterDone == 'all'){
      return true
    }

    if (this.state.filterDone == 'done'){
      return this.state.jobtasks['node_' + node_id].done    
    }

    if (this.state.filterDone == 'not done'){
      return !this.state.jobtasks['node_' + node_id].done    
    }
  }

  sortNetwork = () => {
    // sort by node ID
    let node_ids = []
    Object.keys(this.state.jobtasks).map(
      function (node_id, i) {
        return node_ids.push(
          parseInt(node_id.replace('node_', ''))
        )
      }
    )
    function sortNumber(a, b) {
      return a - b;
    }
    return node_ids.sort(sortNumber).reverse().filter(this.filterByNodeName).filter(this.filterByDone)
  }

  async getBatchTasks(all) {
    
    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    let url = "/batch?batch_id=" + this.state.job_id
    if (this.state.lastKey) {
      url += '&last_key=' + this.state.lastKey
    }
    if (all) {
      url += '&job_tasks_limit=-1'
    }
    
    let response = await API.get("backend", url, options);
    const getNodeName = (n) => n['_intData']['blockId']+', '+n['_intData']['type'];

    let nodeTypes = []
    try{
      nodeTypes = response.network.map(getNodeName).filter((v, i, a) => a.indexOf(v) === i)
    }
    catch(e){
      console.log(e)
    }

    
    this.setState({
      isLoading: false,
      job: { id: this.state.job_id, ...response },
      jobtasks: { ...this.state.jobtasks, ...response.jobtasks },
      lastKey: response.last_key,
      jobtasks: response.tasks,
      ecsTasksCount: response.ecs_tasks_count,
      nodeTypes: nodeTypes
    });

  }

  // // LOAD MORE
  // handleLoadMore = data => {
  //   this.setState({ isLoadingNewPage: true });
  //   this.getBatchTasks();
  // };


  renderBatchtaskList() {
    let network = this.sortNetwork()
    
    return network.map(
      (node_id, i) =>
      {
        let jobtask_id = this.state.jobtasks['node_' + node_id].type + "_" + this.state.jobtasks['node_' + node_id].id;
        return (

        <tr key={i}>
          <td className="checkbox-container">
            <Form.Check 
              type='checkbox'
              id={jobtask_id}
              checked={Object.keys(this.state.checked).includes(jobtask_id) ? 'checked' : ''}
              onChange={(event) => {
                
                let newState = this.state.checked;
                if (event.target.checked){
                  newState[jobtask_id] = true;
                }
                else{
                  delete newState[jobtask_id];
                  
                }
                this.setState({
                  checked: newState
                })
              }}
              
            />
          </td>
          <td>
            <Nav.Item>
              <Nav.Link href={"/batch/"+jobtask_id}>{this.state.jobtasks['node_' + node_id].name}</Nav.Link>
            </Nav.Item>
            <small className="jobtaskSawCommand">
              sessions: [ {this.state.jobtasks['node_' + node_id].sessions ? this.state.jobtasks['node_' + node_id].sessions : 'all'} ]
            </small><br/>
            <small className="jobtaskSawCommand">
              { this.renderSawTaskCommand(this.state.job, this.state.jobtasks['node_' + node_id]) }
            </small>
          </td>
          <td>{this.state.jobtasks['node_' + node_id].done ? 'yes' : 'no'}</td>
          <td>{this.state.jobtasks['node_' + node_id].message ? this.state.jobtasks['node_' + node_id].message : ""}</td>
        </tr>
        )
      }
    );
  }

  renderSawCommand(job) {
    if (!job || !job.network){
      return (<><small>saw get --start -4h /limbic-workers --prefix /ecs/{process.env.REACT_APP_STAGE}</small></>)
    }
    return (<><small>saw get --start -4h /limbic-workers --prefix /ecs/{process.env.REACT_APP_STAGE}/{job.id}</small></>)
  }

  renderSawTaskCommand(job, task) {
    if (!job || !job.network){
      return (<></>)
    }
    else if (task.ecs_task){
      let [ecs_cluster, ecs_task] = task.ecs_task.split('/')
      return (
        <small>saw get --start -4h /limbic-workers --prefix /ecs/{process.env.REACT_APP_STAGE}/{job.id}/{ecs_cluster}/limbic_worker/{ecs_task}</small>
      )
    }
    return (<></>)
  }

  // filter by node type & done
  // allow to select all or individual
  // rerun selected 
  render() {
    
    return (
      <div className="jobtasks">

        <>
          <Card>
            <Card.Body>
              <Card.Title><h4>Batch #{this.state.job_id}</h4></Card.Title>
              
              <Table size="lg" className="filter-table">
                <tbody>
                <tr>
                  <td>
                    <div className="header-container">
                      <h5>Logs</h5>
                      <Alert variant='info' className="log-alert">
                        { this.renderSawCommand(this.state.job) }
                      </Alert>
                    </div>

                  </td>
                  <td>
                    <div className="header-container">
                      <h5>ECS tasks</h5>
                      <Alert variant='info' className="log-alert">
                        Running: { this.state.ecsTasksCount }
                      </Alert>
                    </div>

                  </td>
                </tr>
                <tr>
                  <td>

                    <div className="header-container">
                      <h5>Filters</h5>
                      <Form.Group className="filter-group">
                        <Form.Label><small>node</small></Form.Label>
                        <Form.Control
                          as="select"
                          className="d-flex justify-content-end filter-dropdown"
                          value={this.state.filterNodeType}
                          onChange={(event) => {
                            this.setState({
                              filterNodeType: event.target.value
                            })
                          }}
                        >
                          {
                            this.state.nodeTypes.map(function (node) {
                              return <option key={node} value={node}>{node}</option>
                            }, this)
                          }
                          <option key="all" value="all">all</option>
                        </Form.Control>
                        <Form.Label><small>job status</small></Form.Label>
                        <Form.Control
                          as="select"
                          className="d-flex justify-content-end filter-dropdown"
                          value={this.state.filterDone}
                          onChange={(event) => {
                            this.setState({
                              filterDone: event.target.value
                            })
                          }}
                        >
              
                          {
                            this.state.doneTypes.map(function (x) {
                              return <option key={x} value={x}>{x}</option>
                            }, this)
                          }
                        </Form.Control>
              
                      </Form.Group>
                    </div>
                  </td>
                  <td>
                    <h5>Actions</h5>
                    <ReRunModal checked={this.state.checked} session={this.props.session}/>

                  </td>
                </tr>
                </tbody>
              </Table>

            {this.state.isLoading
              ? <CenteredSpinner />
              : <>
              <Table bordered responsive size="lg">
                <thead>
                  <tr>
                    <th className="checkbox-all-container">
                      <Form.Check 
                        type='checkbox'
                        id='all-checks'
                        onChange={(event) => {
                          let newState = [];
                          
                          if (event.target.checked){
                            let network = this.sortNetwork()
                            let jobtasks = network.map((node_id, i) => {
                              return this.state.jobtasks['node_' + node_id].type + "_" + this.state.jobtasks['node_' + node_id].id;
                            })
                            newState = Object.assign({}, ...jobtasks.map((x) => ({[x]: true})));
                            
                          }

                          this.setState({
                            checked: newState
                          })
                        }}
                        
                      />
                    </th>
                    <th><h6>Task</h6></th>
                    <th><h6>Done</h6></th>
                    <th><h6>Message</h6></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(this.state.jobtasks).length > 0
                    ? this.renderBatchtaskList()
                    : <tr><td colSpan="4">{this.state.isLoading ? "" : "No results yet"}</td></tr>}
                </tbody>
              </Table>
              {/* <Button variant="outline-secondary" disabled={!this.state.lastKey} onClick={this.handleLoadMore}>Load more</Button> */}
              <Button variant="outline-secondary" className="load-all" onClick={this.handleLoadAll}>Load all</Button>
              </>
              }

            </Card.Body>
          </Card>

          {this.state.job && this.state.job.report &&
            <Card className="mt-2">
              <Card.Body>
                <Card.Title onClick={() => this.setState({ showReport: !this.state.showReport })}>
                  Report <small>({this.state.showReport ? "hide" : "show"})</small>
                </Card.Title>
                { this.state.showReport && 
                  <Table bordered responsive size="lg">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Event</th>
                    </tr>
                  </thead>
                  <tbody>
                  { this.state.job.report.map(
                    (event, i) =>
                      <tr key={i}>
                        <td>{new Date(event.timestamp).toISOString()}</td>
                        <td>{event.message}</td>
                      </tr>
                  ) }
                  </tbody>
                </Table>
              }
              </Card.Body>
            </Card>
          }

          {this.state.job &&
            <Card className="mt-2">
              <Card.Body>
                <Card.Title>Details</Card.Title>
                <BatchForm
                  setMessage={this.props.setMessage}
                  session={this.props.session}
                  batch={this.state.job}
                />
              </Card.Body>
            </Card>
          }
        </>

      </div>
    );
  }
}

