import React, { Component } from "react";
import { API, Cache } from "aws-amplify";
import CenteredSpinner from "../components/CenteredSpinner";
import { Button, Card, Table, Nav } from "react-bootstrap";

import { faSyncAlt  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import 'brace/mode/json';
import 'brace/theme/github';

import "./Networks.css";

export default class Networks extends Component {
 constructor(props) {
    super(props);

    this.state = {
      lastKey: false,
      isLoading: true,
      files: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getListFiles();
      
    } catch (e) {
      alert(e);
    }
  }

  async getListFiles() {
    this.setState({ isLoading: true });

    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    let url = "/networks"

    let response = Cache.getItem(url)
    if (!response){
      response = await API.get("backend", url, options);
      Cache.setItem(url, response)
    }
    
    this.setState({
      isLoading: false,
      files: response
    });
  }

  invalidateCache = () => {
    Cache.getAllKeys().map((key) => {
      if (key.startsWith('/networks')){
        console.log('invalidate ' + key)
        Cache.removeItem(key)
      }
      return true
    })
  }

  handleSync = () => {
    this.setState({ isLoadingNewPage: false, files: [], lastKey: false }, function(){
      this.invalidateCache()
      this.getListFiles()      
    });
  }


  handleStartJob = (e, item) => {
    window.confirm(
      "todo..."
    );
    return 
  }

  handleDeleteFile = (e, item) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this file?"
    );
    if (!confirmed) {
      return;
    }

    this.props.setMessage('Please wait')
    
    API.del("backend", "/networks", {
      body: {
       username: this.props.session.idToken.payload.email,
       path: item['path'] 
     },
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
        this.props.setMessage('Network deleted', true)        
    }).catch(error => {
        this.props.setMessage(error.response)
    });
  }

  
  renderFiles(files){
    
    return files.map(
      (file, i) =>
          <tr key={file.path}>
            <td>
              <Nav.Item>
                <Nav.Link href={ "/emodarwin/" + encodeURIComponent(file.path) }>
                  { file.path.replace('/', ' / ') }
                </Nav.Link>
              </Nav.Item>
            </td>
            <td>{ file.created }</td>
            <td>{ file.last_update }</td>
            <td>{ file.message }</td>
            <td>{ file.author }</td>
            <td>
                <Button variant="primary" size="sm" 
                  onClick={((e) => this.handleStartJob(e, file))}>
                    run
                </Button>
            </td>
            <td>
                <Button variant="primary" size="sm" 
                  onClick={((e) => this.handleDeleteFile(e, file))}>
                    delete
                </Button>
            </td>
          </tr>
    );
  }
  
  render() {
    return (
      <div className="network">
  
        <>

        <Card className="network">
          <Card.Body>
            <Button variant="outline-secondary" onClick={this.handleSync} className="float-right sync-button">
              <FontAwesomeIcon icon={faSyncAlt} /> Sync
            </Button>
            <Card.Title>Networks</Card.Title>
 
            <Table bordered responsive size="sm">
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Created</th>
                  <th>Last Update</th>
                  <th>Description</th>
                  <th>Author</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
        { !this.state.isLoading ?
              <tbody>
                { this.renderFiles(this.state.files) }
              </tbody>
        : 
              <tbody>
                <tr key='loading'>
                  <td colSpan="8"><CenteredSpinner /></td>
                </tr>
              </tbody>
        }
            </Table>
          </Card.Body>
        </Card>


        </>
      </div>
    );
  }
}

 