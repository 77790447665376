import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";

import Search from "./containers/Search";
import EmoDarwin from "./containers/EmoDarwin";
import Notebooks from "./containers/Notebooks";
import Networks from "./containers/Networks";
import NetworkDetails from "./containers/NetworkDetails";
import Jobs from "./containers/Jobs";

import JobsTasks from "./containers/JobsTasks";
import JobsTasksResults from "./containers/JobsTasksResults";
import Batch from "./containers/Batch";
import BatchTasks from "./containers/BatchTasks";
import BatchTasksResults from "./containers/BatchTasksResults";

import Workers from "./containers/Workers";
import PivotTable from "./containers/PivotTable";
import ResetPassword from "./containers/ResetPassword";

import Swagger from "./containers/Swagger";

import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <AuthenticatedRoute path="/docs" exact component={Swagger} props={childProps} />
    <AuthenticatedRoute path="/search" exact component={Search} props={childProps} />
    <AuthenticatedRoute path="/notebooks" exact component={Notebooks} props={childProps} />
    <AuthenticatedRoute path="/workers" exact component={Workers} props={childProps} />
    <AuthenticatedRoute path="/networks" exact component={Networks} props={childProps} />
    <AuthenticatedRoute path="/networks/:path" exact component={NetworkDetails} props={childProps} />
    <AuthenticatedRoute path="/pivottable" exact component={PivotTable} props={childProps} />
    <AuthenticatedRoute path="/batch" exact component={Batch} props={childProps} />
    <AuthenticatedRoute path="/batch/batch_:id" exact component={BatchTasks} props={childProps} />
    <AuthenticatedRoute path="/batch/batchtask_:id" exact component={BatchTasksResults} props={childProps} />
    <AuthenticatedRoute path="/jobs" exact component={Jobs} props={childProps} />
    <AuthenticatedRoute path="/jobs/jobtask_:id" exact component={JobsTasksResults} props={childProps} />
    <AuthenticatedRoute path="/jobs/job_:id" exact component={JobsTasks} props={childProps} />
    <AuthenticatedRoute path="/emodarwin" exact component={EmoDarwin} props={childProps} />
    <AuthenticatedRoute path="/emodarwin/:path" exact component={EmoDarwin} props={childProps} />

    
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;