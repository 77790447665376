import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, Alert } from "react-bootstrap";
import Routes from "./Routes";
import metadata from './metadata.json';


import "./App.css";

// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap-css-only/css/bootstrap.min.css";
// import "mdbreact/dist/css/mdb.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      session: false,
      buildId: false,
      message: false
    };

    this.setMessage = this.setMessage.bind(this);
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      this.userHasAuthenticated(true, session);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }

    this.setState({ isAuthenticating: false, buildId: metadata.build.slice(0, 12) });
  }

  userHasAuthenticated = (authenticated, session) => {
    this.setState({ isAuthenticated: authenticated, session: session });
  }

  setMessage = (msg, reload) => {
    window.scrollTo(0, 0)
    this.setState({message: msg});
    let timeout = 35000
    if (typeof reload !== 'undefined')
      timeout = 3000
    setTimeout(
      function() {
          this.setState({message: false});
          // if (typeof reload !== 'undefined'){
          //   window.location.reload(false)
          // }
      }
      .bind(this), timeout);
  }

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false, false);
    this.props.history.push("/login");
  }
  
  handleReload = () => {
    window.location.reload();
  };

  render() {
    const childProps = {
      session: this.state.session,
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setMessage: this.setMessage
    };


    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        <Navbar bg="light" expand="lg" collapseOnSelect>
          <Navbar.Brand>
            <Link to="/">Limbic Web UI <small><small>(build { this.state.buildId })</small></small></Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              {this.state.isAuthenticated
                ? <Fragment>
                    <Nav.Item>
                      <Nav.Link href="/docs"><small>docs</small></Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link href="/search"><small>search</small></Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link href="/networks"><small>networks</small></Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link href="/batch"><small>batch</small></Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link href="/jobs"><small>jobs</small></Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link href="/workers"><small>clusters</small></Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link href="/notebooks"><small>notebooks</small></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={this.handleLogout}><small>| logout</small></Nav.Link>
                    </Nav.Item>
                  </Fragment>
                : <Fragment>
                    <Nav.Item>
                      <Nav.Link href="/signup"><small>signup</small></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="/login"><small>login</small></Nav.Link>
                    </Nav.Item>
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        { this.state.message 
          ? <Alert className="mt-3" key={"status_msg"} variant="info">{this.state.message}</Alert>
          : ""
        }

        <Routes childProps={childProps} />

        <Navbar bg="light" className="mt-2 mb-1 justify-content-end">
          <div className="small justify-content-end text-secondary">&copy; emotiv 2019</div>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(App);
