import React, { Component } from "react";
import { API } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
// import ReactBootstrapSlider from 'react-bootstrap-slider';
import DatePicker from "react-datepicker";
import AceEditor from 'react-ace';
import { parseISO } from 'date-fns';


import 'brace/mode/json';
import 'brace/theme/github';
 
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css"

export default class SearchForm extends Component {

  constructor(props) {
    super(props)

    let today = new Date();
    let monthsAgo = new Date();
    monthsAgo.setMonth(monthsAgo.getMonth() - 3);
    
    this.state = {
      isLoading: null,
      sex: { "M": false, "F": false, "X": false, "U": false, "Y": false },
      headset: { "EPOC": false, "EPOCPLUS": false, "EPOCFLEX": false, "INSIGHT": false, "MN8": false },
      age: { "min": 10, "max": 80 },
      collectionDate: { "start": monthsAgo, "end": today },
      name: "",
      application: "",
      tags: ""
    };
    
  }

  async componentDidMount() {
    this.updateSearchJson()
  }

  updateSearchJson() {
    let json = {
      "size": 600,
      "stored_fields": [],
      "query": { "bool": { "must": [] } }
    }

    var zeroPad = function(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    }
    
    var formatESDate = function(date, time=true){
      let d = date.getFullYear().toString() + '-' + zeroPad(date.getMonth() + 1, 2) + '-'  + zeroPad(date.getDate(), 2)
      if (time)
        return d + 'T00:00:00.0+00:00'
      else
        return d
    }


    // sex
    var sex = false
    for (let key in this.state.sex) {
      if (this.state.sex.hasOwnProperty(key) && this.state.sex[key] ) {
          if (!sex)
            sex = { "bool": { "should": [ ] } }
          sex['bool']['should'].push({ "term": { "sex": key } })
      }
    }
    if (sex)
      json['query']['bool']['must'].push(sex)

    // headset
    var headset = false
    for (let key in this.state.headset) {
      if (this.state.headset.hasOwnProperty(key) && this.state.headset[key] ) {
          if (!headset)
            headset = { "bool": { "should": [ ] } }
          headset['bool']['should'].push({ "term": { "data.headsetInfo.headsetType": key } })
      }
    }
    if (headset)
      json['query']['bool']['must'].push(headset)

    // age 
    var start = new Date()
    start.setYear(start.getFullYear() - parseInt(this.state.age['max']))
    var end = new Date()
    end.setYear(end.getFullYear() - parseInt(this.state.age['min']))
    json['query']['bool']['must'].push({
        "range": {
            "owner_dob": {
                "gte": formatESDate(start, false),
                "lt":  formatESDate(end, false,)
            }
        }
    })

    // created 
    json['query']['bool']['must'].push({
        "range": {
            "created": {
                "gte": formatESDate(this.state.collectionDate['start']),
                "lt":  formatESDate(this.state.collectionDate['end'])
            }
        }
    })

    // tags
    var tags = false
    if (this.state.tags.length > 0){
      tags = { "bool": { "should": [ ] } }
      this.state.tags.split(",").map(function(item) {
        return tags['bool']['should'].push({ "term": { "records.data.tags": item.trim() } })
      });
    }
    if (tags)
      json['query']['bool']['must'].push(tags)

    // application
    var application = false
    if (this.state.application.length > 0){
      application = { "bool": { "should": [ ] } }
      this.state.application.split(",").map(function(item) {
        return application['bool']['should'].push({ "term": { "records.data.applicationId": item.trim() } })
      });
    }
    if (application)
      json['query']['bool']['must'].push(application)

    this.setState({ search_json: JSON.stringify(json, null, 2) })
  }


  // save form validator 
  validateForm() {
    return (this.state.name.length > 0);
  }


  // FORM HANDLERS
  handleAgeChange = event => {
    this.setState({ age: {
            "min": event.target.value[0], 
            "max": event.target.value[1] } 
    }, function(){
      this.updateSearchJson()
    }) 
  }

  handleDateStartChange = date => {
    let stateObj = this.state.collectionDate
    stateObj['start'] = date 
    this.setState({ stateObj }, function(){
      this.updateSearchJson()
    }) 
  }

  handleDateEndChange = date => {
    let stateObj = this.state.collectionDate
    stateObj['end'] = date 
    this.setState({ stateObj }, function(){
      this.updateSearchJson()
    }) 
  }

  handleChange = event => {
    if (event.target === undefined){
      return
    }
    else if (event.target.type === 'checkbox'){
      // checkbox
      let checkbox_name = event.target.name.split('_')[0] 
      let filter_name = event.target.name.split('_')[1] 
  
      let state_obj = this.state[checkbox_name]
      state_obj[filter_name] = event.target.checked
  
      this.setState({ [checkbox_name]: state_obj }, function(){
        this.updateSearchJson()
      }) 
    }
    else{
      // text input
      this.setState({ [event.target.name]: event.target.value }, function(){
        this.updateSearchJson()
      }) 

    }
  }

  handleJsonUpdate = raw => {
    this.setState({ search_json: raw })
  }

  loadSearch = (name, search_obj) => {
    // presets search form, from saved search obj
    this.setState({ 
      sex: search_obj['sex'],
      headset: search_obj['headset'],
      age: search_obj['age'],
      collectionDate: {
        start: parseISO(search_obj['collectionDate']['start'].split('T')[0]),
        end: parseISO(search_obj['collectionDate']['end'].split('T')[0])
      },
      tags: search_obj['tags'],
      application: search_obj['application'],
      name: name
    }, function(){
      this.updateSearchJson()
    })
  }

  handleSave = async event => {    
    event.preventDefault();
    event.stopPropagation();

    API.post("backend", "/search", {
      body: {
        username: this.props.session.idToken.payload.email,
        name: this.state.name,
        search_obj: {
          'sex': this.state.sex,
          'headset': this.state.headset,
          'age': this.state.age,
          'collectionDate': this.state.collectionDate,
          'application': this.state.application,
          'tags': this.state.tags
        }
      },
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
        this.setState({ isLoading: false });
        this.props.setMessage('Search settings saved')
        this.props.handleSync()
        this.handleSubmit(event)
        
    }).catch(error => {
        this.props.setMessage(error.response)
    });
    
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    var form_this = this;
    this.props.handleSearch(this.state.search_json, function(){
      form_this.setState({ isLoading: false });
    })
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>

        <h3>User attributes</h3>
 
        <Form.Group className="m-2">        
          <Form.Label className="font-weight-bold">Gender</Form.Label>
          <Form.Check 
            type="checkbox"
            name="sex_M"
            label="male"
            checked={this.state.sex['M']} 
            onChange={this.handleChange}
          />
          <Form.Check 
            type="checkbox"
            name="sex_F"
            label="female"
            checked={this.state.sex['F']} 
            onChange={this.handleChange}
          />
          <Form.Check 
            type="checkbox"
            name="sex_Y"
            label="male to female"
            checked={this.state.sex['Y']} 
            onChange={this.handleChange}
          />
          <Form.Check 
            type="checkbox"
            name="sex_X"
            label="female to male"
            checked={this.state.sex['X']} 
            onChange={this.handleChange}
          />
          <Form.Check 
            type="checkbox"
            name="sex_U"
            label="unspecified"
            checked={this.state.sex['U']} 
            onChange={this.handleChange}
          />
        </Form.Group>

        {/* <div className="m-2 font-weight-bold">Age ({this.state.age.min} - {this.state.age.max})</div>

        <ReactBootstrapSlider
            slideStop={this.handleAgeChange}
            value={[ this.state.age.min, this.state.age.max ]}
            step={1}
            max={120}
            min={1}
            className="m-2"
            />

        <h3 className="mt-5">Recording attributes</h3> */}


        <Form.Group className="m-2">

        <Form.Label className="font-weight-bold">collection start date (dd/mm/yyyy)</Form.Label><br />
        <DatePicker
          name="start"
          dateFormat="dd/MM/yyyy"
          selected={this.state.collectionDate['start']}
          onChange={this.handleDateStartChange}
        />

        </Form.Group>
        <Form.Group className="m-2">

        <Form.Label className="font-weight-bold">collection end date (dd/mm/yyyy)</Form.Label><br />
        <DatePicker
          name="end"
          dateFormat="dd/MM/yyyy"
          selected={this.state.collectionDate['end']}
          onChange={this.handleDateEndChange}
        />

        </Form.Group>

        <Form.Group className="m-2">        
          <Form.Label className="font-weight-bold">Headset</Form.Label>
          <Form.Check 
            type="checkbox"
            checked={this.state.headset['EPOC']} 
            name="headset_EPOC"
            label="epoc"
            onChange={this.handleChange}
          />
          <Form.Check 
            type="checkbox"
            checked={this.state.headset['EPOCPLUS']} 
            name="headset_EPOCPLUS"
            label="epoc plus"
            onChange={this.handleChange}
          />
          <Form.Check 
            type="checkbox"
            checked={this.state.headset['EPOCFLEX']} 
            name="headset_EPOCFLEX"
            label="epoc flex"
            onChange={this.handleChange}
          />
          <Form.Check 
            type="checkbox"
            checked={this.state.headset['INSIGHT']} 
            name="headset_INSIGHT"
            label="insight"
            onChange={this.handleChange}
          />
          <Form.Check 
            type="checkbox"
            checked={this.state.headset['MN8']} 
            name="headset_MN8"
            label="mn8"
            onChange={this.handleChange}
          />
        </Form.Group>

        <Form.Label className="m-2 font-weight-bold">tags (e.i. "yoga, reading books, meditation")</Form.Label>
        <Form.Control
          name="tags"
          onChange={this.handleChange}
          value={this.state.tags}
          type="input" 
          className="m-2"
        />

        <Form.Label className="m-2 font-weight-bold">application IDs (e.i. "com.emotiv.emotivpro, com.something.else") </Form.Label>
        <Form.Control
          name="application"
          onChange={this.handleChange}
          value={this.state.application}
          type="input"
          className="m-2" 
        />
        
        <AceEditor
          mode="json"
          value={ this.state.search_json }
          theme="github"
          onChange={ (raw)=>{this.handleJsonUpdate(raw)} }
          width="100%"
          maxLines={50}
          minLines={20}
          name="search_json"
          className="m-2"
        />

        <h3 className="mt-5">Save search</h3>

        <Form.Label className="m-2 font-weight-bold">name</Form.Label>
        <Form.Control
          name="name"
          onChange={this.handleChange}
          value={this.state.name}
          type="input"
          className="m-2" 
        />

        <LoaderButton
          block
          type="submit"
          name="search"
          isLoading={this.state.isLoading}
          text="search"
          loadingText="searching"
           className="w-25 p-3 m-2 float-right"
        />

        <LoaderButton
          block
          disabled={!this.validateForm()}
          onClick={this.handleSave}
          isLoading={this.state.isLoading}
          name="save"
          text="save and search"
          className="w-25 p-3 m-2 float-right"
          loadingText="searching"
        />
      </Form>
      
    );
  }
}
