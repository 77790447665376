const config = {
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_QixjBWeYc",
    APP_CLIENT_ID: "371n6b56egn96ti2o8qacui63l",
    IDENTITY_POOL_ID: "us-east-1:3e4886c8-e740-43ca-9923-2e2db92c59d1"
  },
  auth: {
    EMAIL_SUFFIX: "@emotiv.com"
  },
  notebook: {
    BASE_URL: "/limbicnb"
  },
  apiGateway: {
    URL: process.env.REACT_APP_API_GATEWAY_URL
  }

};

const axios = require('axios');
axios.defaults.timeout = 1000*60; // 60sec


export default {
  // Add common config values here
  ...config
};

