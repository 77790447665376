import React, { Component } from "react";
import { API, Cache } from "aws-amplify";
import SearchForm from "../components/SearchForm";
import CenteredSpinner from "../components/CenteredSpinner";
import { Button, Card, Table } from "react-bootstrap";
import AceEditor from 'react-ace';


import 'brace/mode/json';
import 'brace/theme/github';

import "./Search.css";

export default class Search extends Component {
 constructor(props) {
    super(props);

    this.searchForm = React.createRef();
    this.handleSync = this.handleSync.bind(this);

    this.state = {
      lastKey: false,
      isLoading: true,
      isLoadingNewPage: true,
      message: false,
      searches: [],
      searchResults: false,
      searchStats: false
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getSearches();
      
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async getSearches() {
    this.setState({ isLoadingNewPage: true });
   
    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    let url = "/search"
    if (this.state.lastKey)
      url += "?last_key="+this.state.lastKey
    
    let response = Cache.getItem(url)
    if (!response){
      response = await API.get("backend", url, options);
      Cache.setItem(url, response)
    }
    
    this.setState({
      isLoading: false,
      isLoadingNewPage: false,
      searches: this.state.searches.concat(response.items),
      lastKey: response.last_key
    });
  }

  // LOAD MORE
  invalidateCache = () => {
    Cache.getAllKeys().map((key) => {
      if (key.startsWith('/search')){
        console.log('invalidate ' + key)
        Cache.removeItem(key)
      }
      return true
    })
  }


  handleSync = () => {
    this.setState({ isLoadingNewPage: false, searches: [], lastKey: false }, function(){
      this.invalidateCache()
      this.getSearches()      
    });
  }

  handleLoadMore = data => {
    this.setState({ isLoadingNewPage: true });
    this.getSearches();
  };

  // ACTIONS
  handleLoadSearch = (e, item) => {
    this.searchForm.current.loadSearch(item['name'], JSON.parse(item['search_obj']))
  }

  handleDeleteSearch = (e, item) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this search preset?"
    );
    if (!confirmed) {
      return;
    }

    this.props.setMessage('Please wait')
    API.del("backend", "/search", {
      body: { id: item['id'] },
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
        this.setState({ isLoading: false });
        this.handleSync()
        this.props.setMessage('Search preset deleted', true)        
    }).catch(error => {
        this.props.setMessage(error.response)
    });
  }

  handleSearch = (search_json, callback) => {
    API.post("backend", "/search_es", {
        body: {
          username: this.props.session.idToken.payload.email,
          query: search_json
        },
        headers: {
          Authorization: this.props.session.idToken.jwtToken
        }
      }).then(response => {
          if (response['error'] !== undefined){
            this.props.setMessage(response['error'])
            this.setState({ 
                searchResults: false,
                searchStats: false }, function(){
                  window.scrollTo(0, 0)
            })
          }
          else{
            this.setState({ 
                searchResults: JSON.stringify(response['data'], null, 2),
                searchStats: response['stats'] }, function(){
                  window.scrollTo(0, 0)
            })
          }
          // notify search form
          callback()
      }).catch( (error) => {
        console.log(error)
          // this.props.setMessage(error)
      });
  }

  renderSearchList(searches) {
    return searches.map(
      (search, i) =>
          <tr key={search.id}>
            <td>{ search.name }</td>
            <td>{ search.created_at }</td>
            <td>{ search.owner ? search.owner : "limbic" }</td>
            <td>
                <Button variant="primary" size="sm" 
                  onClick={((e) => this.handleLoadSearch(e, search))}>
                    load
                </Button>
                <Button variant="primary" size="sm" 
                  onClick={((e) => this.handleDeleteSearch(e, search))}>
                    delete
                </Button>
            </td>
          </tr>
    );
  }
  
  render() {
    return (
      <div className="search">
        { !this.state.isLoading ?
        <>
        { this.state.searchResults ?
        <Card className="mb-2">
          <Card.Body>
            <Card.Title>Results</Card.Title>
            <div>Search took { this.state.searchStats.took } milliseconds, 
                returned { this.state.searchStats.size } out 
                of { this.state.searchStats.total } matched sessions  
            </div>
            <AceEditor
            mode="json"
            value={ this.state.searchResults }
            theme="github"
            width="100%"
            maxLines={50}
            minLines={20}
            name="search_results"
            className="m-2"
          />
          </Card.Body>
        </Card>
        : ''
        }

        <Card>
          <Card.Body>
            <Card.Title>Saved Searches</Card.Title>
            
            <Table bordered responsive size="lg">
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Created</th>
                  <th>User</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                { this.renderSearchList(this.state.searches) }
              </tbody>
            </Table>
            { this.state.isLoadingNewPage 
              ? <CenteredSpinner />
              : <>
                  <Button variant="outline-secondary" disabled={!this.state.lastKey} onClick={this.handleLoadMore}>Load more</Button>
                </>
            }
          </Card.Body>
        </Card>

        <Card className="mt-2">
          <Card.Body>
            <SearchForm setMessage={ this.props.setMessage } 
                        ref={this.searchForm} 
                        handleSearch={this.handleSearch}
                        handleSync={this.handleSync} 
                        session={ this.props.session }  />
          </Card.Body>
        </Card>
        </>

        : <CenteredSpinner />
        
        }
      </div>
    );
  }
}

 