import React, { Component } from "react";
import CenteredSpinner from "../components/CenteredSpinner";
import NetworkForm from "../components/NetworkForm";
import { Card } from "react-bootstrap";

import 'brace/mode/json';
import 'brace/theme/github';

import "./Networks.css";

export default class NetworkDetails extends Component {
 constructor(props) {
    super(props);

    this.state = {
      lastKey: false,
      isLoading: true,
      path: decodeURIComponent(props.match.params.path)
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ isLoading: false });
  }
  
  render() {
    return (
      <div className="network">
        { !this.state.isLoading ?
        
        <Card>
          <Card.Body>

            <Card.Title>{ this.state.path }</Card.Title>
            <NetworkForm setMessage={ this.props.setMessage } session={ this.props.session } path= { this.state.path } />    

          </Card.Body>
        </Card>


        : <CenteredSpinner />
        
        }
      </div>
    );
  }
}

 