import React, { Component } from "react";
import { API } from "aws-amplify";
import {Card, Form } from "react-bootstrap";
import AceEditor from 'react-ace';
import LoaderButton from "../components/LoaderButton";


import 'brace/mode/json';
import 'brace/theme/github';

import "./EmoDarwin.css";


// define emodarwin app
window.emo = {}

// set api timeout to 10s
const axios = require('axios');
axios.defaults.timeout = 10000; 


export default class EmoDarwin extends Component {
  

  constructor(props) {
    super(props);

    this.state = {
      path: decodeURIComponent(props.match.params.path),
      isLoading: true,
      isSubmitting: false,
      commitId: false,
      name: "",
      description: "",
      network: "",
      created: "",
      last_update: "",
      author: "",
      history: []
    };
   
    // allow emodarwin to update network
    window.emo.reactAppUpdateNetwork = function(nodes_list){
      // console.log(JSON.stringify({ network: nodes_list }, null, 2) )
      this.setState({ network: JSON.stringify({ network: nodes_list }, null, 2) })
    }.bind(this)

  }


  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    function loadScript(url) {
        return new Promise(function(resolve, reject) {
            var script = document.createElement("script");
            script.onload = resolve;
            script.onerror = reject;
            script.src = url;
            document.getElementsByTagName("head")[0].appendChild(script);
        });
    }

    // load dependencies
    let app = this
    Promise.all( [
      loadScript("/emoDarwin/js/jquery-3.2.1.min.js"), 
      loadScript("/emoDarwin/js/jsplumb/jquery.jsPlumb-1.5.5.js"), 
      loadScript("/emoDarwin/js/jquery-ui-1.10.4.custom.js")
      ] ).then(function() {

        console.log('libs loaded')
        Promise.all( [
          loadScript("/emoDarwin/js/builder.js"),
          loadScript("/emoDarwin/js/blocks.js")
        ] ).then(function() {

          console.log('emodarwin loaded')
          if (typeof app.state.path !== 'undefined' ){
            // load network 
            try {
              app.setFileDetails(false);

            } catch (e) {
              alert(e);
            }
          }

          app.setState({ isLoading: false })
        })
      })
    
  }


  async updateEmoDarwin(network){
    try {
      window.emo.network = network['network']
      window.emo.loadNetwork()

    } catch (e) {
      alert("Network definition error: "+e);
    }

  }

  async setFileDetails(commitId) {
    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    let url = "/networks?path="+this.state.path
    // if no commitId given load most recent 
    if (commitId)
      url += "&commit_id="+commitId

    this.setState({ isLoading: true });

    const response = await API.get("backend", url, options);
    this.setState({
      isLoading: false,
      commitId: response['commit_id'],
      name: response['path'],
      description: response['message'],
      author: response['author'],
      network: response['content'],
      created: response['created'],
      history: response['history'],
      last_update: response['last_update']
    });

    this.updateEmoDarwin(JSON.parse(response['content']))
  }

  validateForm() {

    // THIS IS PROBLEMATIC... 
    // we dont rerender on changes in json editor... save button stays disabled even when valid JSON network 
    // try {
    //     JSON.parse(this.state.network);
    // } catch(e) {
    //     console.log(e)
    //     return false
    // }
  

    if ( /^([a-z0-9\s_@\-^!#$%&+={}[\]]+)+([a-z0-9\s_@\-^!#$%&+={}[\]/]*)+\.json$/i.test(this.state.name) === false)
      // network name must be a valid linux path
      return false

    return true

  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.props.setMessage('Please wait')
    this.setState({ isSubmitting: true });
    
    API.post("backend", "/networks", {
      body: {
        username: this.props.session.idToken.payload.email,
        path: this.state.name,
        description: this.state.description,
        network: this.state.network
     },
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
        this.setState({ isSubmitting: false });
        this.props.setMessage('Network saved', true)        
    }).catch(error => {
        this.props.setMessage(error.response)
    });
    
  }

  // JSON editor related
  handleNetworkUpdate = val => {

    this.setState({ network: val })
  }

  handleEditoroOnBlur = event => {
    
    this.updateEmoDarwin(JSON.parse(this.state.network))
  }

  handleLoadVersion = event => {
    if (this.state.commitId !== event.target.value)
      this.setFileDetails(event.target.value)
  }

  handleStartJob = event => {
    window.confirm(
      "todo..."
    );
    return 
  }


  render() {
    return (
      <div ref={el => (this.div = el)} >
      <div className="emo-darwin-container" >
  
        <>
        <Card>
          <Card.Body>

            <Card.Title>{ this.state.path }</Card.Title>
              
              <div className="header">
                  <button id="load">Load Network</button>
                  <button id="save">Save Network</button>
                  <div className="jsoninput">
                      <form>
                          <input type='text' name='jsonnetwork' placeholder='Paste json definition of network here' size={100} />
                          <button id='loadnetwork'> OK </button>
                      </form>
                  </div>
                  <div className='vspace5'>
                  </div>
              </div>
              <div className="main">
                  <div id="modal-background"></div>
                  <div id="modal-content">
                      <button id="modal-close">Close</button>
                      <div className="vspace5"></div>
                      <div id="modal-content-data"></div>
                      <button id="modal-close">Close</button>
                  </div>
                  <div id="info-background"></div>
                  <div id="info-content">
                      <button id="info-close">Close</button>
                      <div className="vspace5"></div>
                      <div id="info-content-data"></div>
                      <button id="info-close">Close</button>
                  </div>
                  <div className="cmenu-background"></div>
                  <div className="cmenu" id="cmenu_add"></div>
                  <div className="cmenu" id="cmenu_edit">
                      <div className="cm_item" id="cm_edit">Edit Node</div>
                      <div className="cm_item" id="cm_copy">Copy Node</div>
                      <div className="cm_item" id="cm_delete">Delete Node</div>
                  </div>
                  <div className="network"></div>
              </div>
            
          </Card.Body>
        </Card>
        </>

      </div>
      <div className="network-details">


        <Card>
          <Card.Body>

            <Form onSubmit={this.handleSubmit}>

              <Form.Group controlId="network">
                <Form.Label>JSON</Form.Label>
                 <AceEditor
                    mode="json"
                    value={this.state.network}
                    onChange={ (raw)=>{this.handleNetworkUpdate(raw)} }
                    onBlur={ (raw)=>{this.handleEditoroOnBlur(raw)} }
                    theme="github"
                    width="100%"
                    maxLines={40}
                    minLines={12}
                    name="network"
                  />
              </Form.Group>

              <Form.Group controlId="description">        
                <Form.Label>Commit message</Form.Label>
                <Form.Control
                  name="description"
                  onChange={this.handleChange}
                  value={this.state.description}
                  type="input" 
                />
              </Form.Group>

              <Form.Group controlId="commit">        
                <Form.Label>Commit</Form.Label>
                <Form.Control
                  name="commit"
                  disabled={true}
                  value={this.state.commitId}
                  type="input" 
                />
              </Form.Group>
                <Form.Group controlId="created">        
                  <Form.Label>Created</Form.Label>
                  <Form.Control
                    disabled={ true } 
                    name="created"
                    value={this.state.created}
                    type="input" 
                  />
                </Form.Group>
                <Form.Group controlId="updated">        
                  <Form.Label>Last update</Form.Label>
                  <Form.Control
                    disabled={ true } 
                    name="updated"
                    value={this.state.last_update}
                    type="input" 
                  />
                </Form.Group>
                <Form.Group controlId="author">        
                  <Form.Label>Author</Form.Label>
                  <Form.Control
                    disabled={ true } 
                    name="author"
                    value={this.state.author}
                    type="input" 
                  />
                </Form.Group>

                <Form.Group controlId="history">        
                  <Form.Label>History</Form.Label>
                  <Form.Control 
                      as="select"
                      className="d-flex justify-content-end owner-dropdown"
                      value={this.state.commitId}
                      onChange={ this.handleLoadVersion }
                    >
                      {
                        this.state.history.map(function (commit) {
                          const commit_name = "[" + commit.created + "] [" + commit.id + "] " + commit.message.slice(0, 24) + " - " + commit.author
                          return <option key={commit.id} value={commit.id}>{commit_name}</option>
                        }, this)
                    }
                  </Form.Control>
                </Form.Group>

                <LoaderButton
                  block
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isSubmitting}
                  text="update"
                  loadingText="updating"
                />
                <LoaderButton
                  block
                  disabled={!this.validateForm()}
                  onClick={this.handleStartJob}
                  text="start job"
                  loadingText="loading"
                />
                
              </Form>

          </Card.Body>
        </Card>

        
      </div>
    </div>
    );
  }
}

 