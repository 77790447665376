import React, { Component } from "react";
import { API } from "aws-amplify";
import CenteredSpinner from "../components/CenteredSpinner";
import { Card, Table } from "react-bootstrap";
import { Link } from 'react-router-dom'


export default class JobsTasksResults extends Component {
 constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      message: false,
      results: false,
      job_id: this.props.location.pathname.split('_')[1],
      task_id: this.props.location.pathname.split('_')[2]      
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getJobTasksResults();      
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async getJobTasksResults() {
    this.setState({ isLoading: true });
   
    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    const url = "/jobresults?jobtask=jobtask_"+this.state.job_id+"&node="+this.state.task_id;
    const response = await API.get("backend", url, options);

    this.setState({
      isLoading: false,
      results: response.item
    });
  }


  render() {
    let download_links = "";
    if (this.state.results.targets){
        download_links = this.state.results.targets.map((item, key) =>
          <li key={item.name}><a href={ item.download } target="_blank" rel="noopener noreferrer" >{item.name}</a></li>
        );
    }
    let analize_links = "";
    if (this.state.results.targets){
        analize_links = this.state.results.targets.map((item, key) =>
          <li key={item.name}>
            <Link to= {{ pathname: "/pivottable", 
                          state: { "s3_location": item.download }
                      }}
                  >
                {item.name}
            </Link>
          </li>
        );
    }
    return (
      <div className="jobtasks">
        { this.state.results ?
        <>
        <Card>
          <Card.Body>
            <Card.Title>Job #{ this.state.job_id }, task #{ this.state.task_id }</Card.Title>
            
            <Table bordered responsive size="lg">
              <thead>
                <tr>
                  <th>Message</th>
                  <th>Download</th>
                  <th>Analize</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.results.message}</td>
                  <td>
                    <ul>
                      { download_links }
                    </ul>
                  </td>
                  <td>
                    <ul>
                      { analize_links }
                    </ul>
                  </td>
                </tr>

              </tbody>
            </Table>

          </Card.Body>
        </Card>
        </>
        : <CenteredSpinner />        
        }
      </div>
    );
  }
}

 