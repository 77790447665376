import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { Card, Table } from "react-bootstrap";
import CenteredSpinner from "../components/CenteredSpinner";

import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


import "./Home.css";
import config from "../config";


export default class Swagger extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backend_endpoint: config['apiGateway']['URL'],
        };
    }


    render() {
        return (
        <div className="Home">
            <SwaggerUI url="https://limbic-backend-staging.emotivcloud.com/spec" /> 
        </div>
        );
    }
}



