import React, { Component } from "react";
import { API } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

export default class NotebooksForm extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isLoading: null,
      name: "",
      password: "",
      streamlit: false,
      ecr_image_name: "cortex_v2",
      ec2_launch_template: "lt-0f095e8d69d945453",
      ec2_instance_type: "t3.micro",
      ec2_instance_vol: 40,
      ec2_instance_swap: 8,
    };
  }

  validateForm() {
    return (this.state.name.length > 0 && this.state.password.length > 0);
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    this.props.setMessage("Please wait");

    API.post("backend", "/notebooks", {
      body: {
        username: this.props.session.idToken.payload.email,
        name: this.state.name,
        password: this.state.password,
        streamlit: this.state.streamlit,
        ecr_image_name: this.state.ecr_image_name,
        ec2_launch_template: this.state.ec2_launch_template,
        ec2_instance_type: this.state.ec2_instance_type,
        ec2_instance_vol: this.state.ec2_instance_vol,
        ec2_instance_swap: this.state.ec2_instance_swap
      },
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }).then(response => {
        this.setState({ isLoading: false });
        if (response['status'] === 200){
          this.props.setMessage('Notebook initilizing...', true)
          this.props.handleSync()
        }
        else{
          this.props.setMessage('Error: '+response["message"], true)
        }

        
    }).catch(error => {
        console.log(error)
        this.props.setMessage(error.response)
    });
      
  }


  render() {
    return (
      <Form onSubmit={this.handleSubmit}>

        <Form.Group controlId="name">        
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            onChange={this.handleChange}
            value={this.state.name}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            onChange={this.handleChange}
            value={this.state.password}
            type="password" 
          />
        </Form.Group>

        <Form.Group controlId="ecr_image_name">
          <Form.Label>ECR image tag</Form.Label>
          <Form.Control
            name="ecr_image_name"
            onChange={this.handleChange}
            value={this.state.ecr_image_name}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="ec2_launch_template">
          <Form.Label>EC2 Launch template ID</Form.Label>
          <Form.Control
            name="ec2_launch_template"
            onChange={this.handleChange}
            value={this.state.ec2_launch_template}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="ec2_instance_type">
          <Form.Label>EC2 Instance type</Form.Label>
          <Form.Control
            name="ec2_instance_type"
            onChange={this.handleChange}
            value={this.state.ec2_instance_type}
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="ec2_instance_type">
          <Form.Label>EC2 Instance volume (GB)</Form.Label>
          <Form.Control
            name="ec2_instance_vol"
            onChange={this.handleChange}
            value={this.state.ec2_instance_vol} 
            type="input" 
          />
        </Form.Group>

        <Form.Group controlId="ec2_instance_type">
          <Form.Label>EC2 Instance swap (GB)</Form.Label>
          <Form.Control
            name="ec2_instance_swap"
            onChange={this.handleChange}
            value={this.state.ec2_instance_swap}
            type="input" 
          />
        </Form.Group>

        <Form.Check 
            type="checkbox"
            name="streamlit"
            label="Streamlit enabled"
            checked={this.state.streamlit} 
            onChange={() => this.setState({ ['streamlit']: !this.state.streamlit })}
          />

        <LoaderButton
          style={{ marginTop: '20px' }}
          block
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="start new notebook"
          loadingText="starting"
        />
      </Form>
      
    );
  }
}
