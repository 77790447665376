import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { Card, Table } from "react-bootstrap";
import CenteredSpinner from "../components/CenteredSpinner";

import "./Home.css";
import config from "../config";



export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      backend_endpoint: config['apiGateway']['URL'],
      stats: {}
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getStats();

    } catch (e) {
      alert(e);
    }

  }

  async getStats() {
    this.setState({ isLoading: true });

    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    let url = "/stats"

    const response = await API.get("backend", url, options);

    console.log(response)

    this.setState({
      isLoading: false,
      stats: response
    });
  }

  setMessage = (msg) => {
    this.setState({ message: msg });
    setTimeout(
      function () {
        this.setState({ message: false });
      }
        .bind(this), 8000);

  }


  renderLander() {
    return (
      <div className="lander">
        <h1>Emotiv Limbic</h1>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderHome() {
    return (
      <div>

        {!this.state.isLoading ?
          <>
            <Card className="Home">
              <Card.Body>
                <Card.Title>Enviroment</Card.Title>

                <Table bordered responsive size="lg">

                  <tbody>
                    <tr><td>backend endpoint: </td><td>{this.state.backend_endpoint}</td></tr>
                    <tr><td>celery broker: </td><td>{this.state.stats.celery_broker}</td></tr>
                    <tr><td>celery default queue prefix: </td><td>{this.state.stats.celery_default_queue_prefix}</td></tr>
                    <tr><td>celery results queue: </td><td>{this.state.stats.workers_results_queue}</td></tr>
                    <tr><td>dynamo db table: </td><td>{this.state.stats.dynamo_table}</td></tr>
                    <tr><td>elasticsearch: </td><td>{this.state.stats.es_host}</td></tr>
                    <tr><td>public cluster autoscaling: </td><td>{this.state.stats.public_cluster_autoscaling ? 'on' : 'off'}</td></tr>
                    <tr><td>public ownership validation: </td><td>{this.state.stats.public_ownership_validation ? 'on' : 'off'}</td></tr>
                    <tr><td>worker limbic env: </td><td>{this.state.stats.worker_limbic_env}</td></tr>
                    <tr><td>worker celery broker: </td><td>{this.state.stats.worker_celery_broker}</td></tr>
                    <tr><td>worker eeg bucket: </td><td>{this.state.stats.worker_eeg_bucket}</td></tr>
                    <tr><td>worker process bucket: </td><td>{this.state.stats.worker_processing_bucket}</td></tr>
                    <tr><td>worker swap size: </td><td>{this.state.stats.worker_swap_size}GB</td></tr>
                    <tr><td>celery sqs prefix: </td><td>{this.state.stats.celery_sqs_prefix}</td></tr>
                    <tr><td>celery sqs results queue: </td><td>{this.state.stats.celery_sqs_results_queue}</td></tr>
                    <tr><td>celery worker instance prefix: </td><td>{this.state.stats.celery_worker_instance_prefix}</td></tr>

                  </tbody>
                </Table>


              </Card.Body>
            </Card>
          </>
          : <CenteredSpinner />

        }
      </div>
    );
  }

  render() {
    return (
      <div className="Home">

        {this.props.isAuthenticated ? this.renderHome() : this.renderLander()}
      </div>
    );
  }
}


