import React, { Component } from "react";
import PivotTableUI from 'react-pivottable/PivotTableUI';

import 'react-pivottable/pivottable.css';

export default class JSPivotTable extends Component {

    constructor(props) {
        super(props);
        this.state = props;
    }

    render() {
        return (
            <PivotTableUI
                data={this.props.initData}
                onChange={s => this.setState(s)}
                {...this.state}
            />
        );
    }
}