import React, { Component } from "react";
import { API, Cache } from "aws-amplify";
import { Form } from "react-bootstrap";
import NotebooksForm from "../components/NotebooksForm";
import CenteredSpinner from "../components/CenteredSpinner";
import { Card, Table, Button} from "react-bootstrap";
import config from "../config";


import { faSyncAlt  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import "./Notebooks.css";

export default class Notebooks extends Component {
 constructor(props) {
    super(props);

    this.state = {
      lastKey: false,
      isLoading: true,
      isLoadingNewPage: true,
      message: false,
      notebooks: [],
      archived: [],
      filter: ''
    };

    this.handleSync = this.handleSync.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      this.getNotebooks();
      
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async getNotebooks() {
    this.setState({ isLoadingNewPage: true });
   
    const options = {
      headers: {
        Authorization: this.props.session.idToken.jwtToken
      }
    }
    let url = "/notebooks"
    if (this.state.lastKey)
      url += "?last_key="+this.state.lastKey
   
    let response = Cache.getItem(url)
    if (!response){
      try {
        response = await API.get("backend", url, options);
      }
      catch (err){
        if (err.response && err.response.status == 401 ){
          window.location.reload();
          return
        }
        else {
          console.log(JSON.stringify(err))
        }
      }
      Cache.setItem(url, response)
    }
    
    this.setState({
      isLoading: false,
      isLoadingNewPage: false,
      notebooks: this.state.notebooks.concat(response.items),
      archived: this.state.notebooks.concat(response.archived),
      lastKey: response.last_key
    });
  }

  // LOAD MORE
  handleLoadMore = data => {
    this.setState({ isLoadingNewPage: true });
    this.getNotebooks();
  };

  invalidateCache = () => {
    Cache.getAllKeys().map((key) => {
      if (key.startsWith('/notebooks')){
        console.log('invalidate ' + key)
        Cache.removeItem(key)
      }
      return true
    })
  }


  // NOTEBOOK ACTIONS 
  handleOpenNotebook = (e, public_ip) => {
    window.open('http://'+public_ip+config.notebook.BASE_URL, "_blank");
  };

  __apiRequestOptions = (id, action) => {
    return {
      headers: {
        'Authorization': this.props.session.idToken.jwtToken
      },
      body: {
        action: action,
        id: id,
        username: this.props.session.idToken.payload.email
      }
    }
  }

  handleStartNotebook = (e, id) => {
    this.props.setMessage("Please wait...");
    API.post("backend", "/notebooks", 
        this.__apiRequestOptions(id, 'start'))
    .then(response => {
        this.props.setMessage("starting...")
        this.handleSync()
    }).catch(error => {
        if (error.response && error.response.status == 401 ){
          window.location.reload();
          return
        }
        else {
          this.props.setMessage(error.response)
        }
    });
  }

  handleStopNotebook = (e, id) => {
    this.props.setMessage("Please wait...");
    API.post("backend", "/notebooks", 
        this.__apiRequestOptions(id, 'stop'))
    .then(response => {
        this.props.setMessage("stopping...")
        this.handleSync()
    }).catch(error => {
        if (error.response && error.response.status == 401 ){
          window.location.reload();
          return
        }
        else {
          this.props.setMessage(error.response)
        }
    });
  }

  handleArchiveNotebook = (e, id) => {
    this.props.setMessage("Please wait...");
    API.post("backend", "/notebooks", 
        this.__apiRequestOptions(id, 'archive'))
    .then(response => {
        this.props.setMessage("notebook archive initiated");
        this.handleSync()
    }).catch(error => {
        this.props.setMessage(error.response)
    });
  }

  handleRestoreNotebook = (e, id, launch_template_id, name) => {
    this.props.setMessage("Please wait...");
    API.post("backend", "/notebooks", {
      headers: {
        'Authorization': this.props.session.idToken.jwtToken
      },
      body: {
        action: 'restore',
        archive_id: id,
        launch_template_id: launch_template_id, 
        name: name,
        username: this.props.session.idToken.payload.email
      }
    }).then(response => {
        this.props.setMessage("notebook restore initiated");
        this.handleSync()
    }).catch(error => {
        this.props.setMessage(error.response)
    });
  }

  handleDeleteNotebook = (e, id) => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this notebook?"
    );

    if (!confirmed) {
      return;
    }

    this.props.setMessage("Please wait...");
    API.post("backend", "/notebooks", 
        this.__apiRequestOptions(id, 'delete'))
    .then(response => {
        this.props.setMessage("notebook deleted");
        this.handleSync()
    }).catch(error => {
        this.props.setMessage(error.response)
    });
  }

  handleSync = () => {
    this.setState({ isLoadingNewPage: false, notebooks: [], lastKey: false }, function(){
      this.invalidateCache()
      this.getNotebooks()      
    });
  }

  renderNotebooksList(notebooks) {
    return notebooks.map(
      (notebook, i) =>
          <tr key={notebook.id}>
            <td>
              <b>{notebook.name}</b>
              { notebook.archive_id && <><br /><small>archived: {notebook.archive_id}</small></> }
            </td>
            <td>
              <b>{notebook.state}</b>
              <br />{notebook.instance_type}
              <br />{notebook.image_tag}
              <br /><small>{notebook.instance_id}</small>
              <br /><small>{notebook.launch_template_id}</small>
              <br /><small>{notebook.created_at.slice(0,16)}</small>
                
            </td>
            <td>
                <Button variant="primary" size="sm"
                  disabled={ !notebook.public_ip }
                  onClick={((e) => this.handleOpenNotebook(e, notebook.public_ip))}>
                    open
                </Button>
            </td>
            <td>
                { notebook.public_ip
                  ?
                <Button variant="primary" size="sm" 
                  disabled={ !notebook.instance_id }
                  onClick={((e) => this.handleStopNotebook(e, notebook.id))}>
                    stop
                </Button>
                  :
                <Button variant="primary" size="sm" 
                  disabled={ !notebook.instance_id }
                  onClick={((e) => this.handleStartNotebook(e, notebook.id))}>
                    start
                </Button>
                }
            </td>
            <td>
                <Button variant="primary" size="sm" 
                  disabled={ !notebook.instance_id }
                  onClick={((e) => this.handleDeleteNotebook(e, notebook.id))}>
                    delete
                </Button>
            </td>
            <td>
                <Button variant="primary" size="sm" 
                  disabled={ !notebook.instance_id }
                  onClick={((e) => this.handleArchiveNotebook(e, notebook.id))}>
                    archive
                </Button>
            </td>

          </tr>
    );
  }

  renderArchiveNotebooksList(archived) {
    let filtered_archived = archived
    if (this.state.filter){
      filtered_archived = archived.filter((notebook) => {
        return notebook.name.toLowerCase().includes(this.state.filter.toLowerCase())
      })
    }
    return filtered_archived.map(
      (notebook, i) =>
          <tr key={notebook.id}>
            <td><b>{notebook.name}</b></td>
            <td><b>{notebook.modified_at}</b></td>
            <td>
                <Button variant="primary" size="sm"
                  onClick={((e) => this.handleRestoreNotebook(e, notebook.id, notebook.launch_template_id, notebook.name))}>
                    restore
                </Button>
            </td>
          </tr>
    );
  }

  
  render() {
    return (
      <div className="notebooks">
        { !this.state.isLoading ?
        <>
        <Card>
          <Card.Body>
            <Card.Title>Notebooks</Card.Title>
            
            <Table bordered responsive size="lg">
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Details</th>
                  <th colSpan="4">Actions</th>
                </tr>
              </thead>
              <tbody>
                { this.renderNotebooksList(this.state.notebooks) }
              </tbody>
            </Table>

            { this.state.isLoadingNewPage 
              ? <CenteredSpinner />
              : <>
                  <Button variant="outline-secondary" disabled={!this.state.lastKey} onClick={this.handleLoadMore}>Load more</Button>
                  <Button variant="outline-secondary" onClick={this.handleSync} className="float-right">
                    <FontAwesomeIcon icon={faSyncAlt} /> Sync
                  </Button>
                </>
            }

          </Card.Body>
        </Card>

        <Card className="mt-2">
          <Card.Body>
            <Card.Title>Archived Notebooks</Card.Title>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="name">        
                <Form.Control
                  style={{width: "30%"}}
                  placeholder="filter..."
                  autocomplete="off"
                  name="name"
                  onChange={(e) => this.setState({filter: e.target.value})}
                  value={this.state.filter}
                  type="input" 
                />
              </Form.Group>
            </Form>
            <Table bordered responsive size="lg">
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Last Snapshot</th>
                  <th colSpan="4">Actions</th>
                </tr>
              </thead>
              <tbody>
                { this.renderArchiveNotebooksList(this.state.archived) }
              </tbody>
            </Table>

          </Card.Body>
        </Card>


        <Card className="mt-2">
          <Card.Body>
            <Card.Title>Start new notebook</Card.Title>
            <NotebooksForm 
              setMessage={this.props.setMessage} 
              session={this.props.session} 
              handleSync={this.handleSync} 
            /> 
          </Card.Body>
        </Card>
        </>

        : <CenteredSpinner />
        
        }
      </div>
    );
  }
}

 
